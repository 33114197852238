import React from 'react'
import PropTypes from 'prop-types'
import ContentWidth from '../../../RadioUI/ContentWidth'

const SeriesPageMain = ({ children }) => {
  return (
    <ContentWidth position="relative" height="max-content">
      {children}
    </ContentWidth>
  )
}

SeriesPageMain.propTypes = {
  children: PropTypes.node
}

export default SeriesPageMain
