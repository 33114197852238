import React, { useEffect, useRef, useState } from 'react'
import Grid from '../../../RadioUI/Grid'
import HeroBackdropImage from '../Components/HeroBackdropImage'
import EpisodeListContainer from '../Components/EpisodeListContainer'
import EpisodeContainer from '../Components/EpisodeContainer'
import { useSeriesIsLoading, useHeroImage } from '../hooks/seriesHooks'
import EpisodeTabsContainer from '../Components/EpisodeTabsContainer'
import EpisodeContributorsContainer from '../Components/EpisodeContributorsContainer'
import { useParams } from 'react-router-dom'
import { useSeasonIsLoading } from '../hooks/seasonHooks'
import calculateScrollToElementPosition from '../helpers/calculateScrollToElementPosition'
import EpisodeListAndEpisodeWrapper from '../Components/EpisodeListAndEpisodeWrapper'
import useHandleFocus from '../hooks/useHandleFocus'
import SeriesPageMain from './SeriesPageMain'
import SeriesPageHeading from './SeriesPageHeading'
import SeriesPageToolbar from './SeriesPageToolbar'
import useSmallScreenToogle from '../hooks/useSmallScreenToogle'
import EpisodeRecommendation from '../Components/EpisodeRecommendation'
import { UrlTimestampProvider } from '../Components/UrlTimestampContext'
import StartFromButtons from '../Components/StartFromButtons'
import { CONTENT_CONTEXTS } from '../../../components/Queues/UpNext/UpNextContext'
import { SeriesSubmissionsProvider } from '../../../components/SeriesSubmissions/SeriesSubmissionsContext'

const Series = () => {
  const [seriesToolbarHeight, setSeriesToolbarHeight] = useState(88)
  const seriesIsLoading = useSeriesIsLoading()
  const seasonIsLoading = useSeasonIsLoading()
  const heroImage = useHeroImage()
  const params = useParams()
  const seriesToolbarRef = useRef(null)
  const episodeListAndEpisodeWrapperRef = useRef(null)
  const { episodeFocusRef } = useHandleFocus()
  const { showSeasonOnSmall } = useSmallScreenToogle()

  useEffect(() => {
    if (seriesIsLoading) return

    const height = seriesToolbarRef.current.getBoundingClientRect().height
    setSeriesToolbarHeight(height)
  }, [seriesIsLoading])

  useEffect(() => {
    if (seriesIsLoading || seasonIsLoading) return

    const scrollTo = calculateScrollToElementPosition(episodeListAndEpisodeWrapperRef.current, seriesToolbarHeight)
    window.scrollTo({ top: scrollTo })
  }, [seriesIsLoading, seasonIsLoading, seriesToolbarHeight])
  return (
    <>
      <HeroBackdropImage
        d={[showSeasonOnSmall ? 'block' : 'none', showSeasonOnSmall ? 'block' : 'none', 'block']}
        src={heroImage.src}
        srcSet={heroImage.srcSet}
      />
      <SeriesPageMain>
        <SeriesPageHeading d={[showSeasonOnSmall ? 'flex' : 'none', showSeasonOnSmall ? 'flex' : 'none', 'flex']} />
        <SeriesPageToolbar ref={seriesToolbarRef} />
        <EpisodeListAndEpisodeWrapper
          ref={episodeListAndEpisodeWrapperRef}
          episodeIsSelected={!!params.programId}
          offsetTop={seriesToolbarHeight}
        >
          <EpisodeListContainer maxW="100%" height="max-content" className="EpisodeListContainer" />
          <Grid className="EpisodeWrapper" rowGap={[4]} maxW="100%" minW="0" height="max-content">
            <EpisodeContainer ref={episodeFocusRef} contentContext={CONTENT_CONTEXTS.SERIES} />
            <StartFromButtons contentContext={CONTENT_CONTEXTS.SERIES} />
            <EpisodeTabsContainer contentContext={CONTENT_CONTEXTS.SERIES} />
            <EpisodeRecommendation />
            <Grid
              gridTemplateColumns={['minmax(0,1fr)', 'minmax(0,1fr)', 'repeat(2, minmax(0,1fr))']}
              columnGap="4"
              rowGap="8"
              maxW="100%"
              minW="0"
            >
              <EpisodeContributorsContainer />
            </Grid>
          </Grid>
        </EpisodeListAndEpisodeWrapper>
      </SeriesPageMain>
    </>
  )
}

Series.propTypes = {}

const SeriesWithProviders = props => {
  return (
    <SeriesSubmissionsProvider>
      <UrlTimestampProvider>
        <Series {...props} />
      </UrlTimestampProvider>
    </SeriesSubmissionsProvider>
  )
}

export default SeriesWithProviders
