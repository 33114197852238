import React from 'react'
import PropTypes from 'prop-types'
import Box from '../../../RadioUI/Box'

const calculateCircle = buttonSize => {
  const radius = buttonSize / 2 - 1
  const circumference = 2 * Math.PI * radius
  const centerxy = buttonSize / 2
  return {
    radius,
    circumference,
    cx: centerxy,
    cy: centerxy
  }
}

const progressToStrokeOffset = (percentage, circumference) => {
  return circumference - (percentage / 100) * circumference
}

const ProgressCircle = ({ percentage = 0, size = 60, accessibilityValue, ...restProps }) => {
  const { radius, circumference, cx, cy } = calculateCircle(size)

  return (
    <Box position="relative" h={`${size}px`} w={`${size}px`} {...restProps}>
      <Box as="svg" position="absolute" w={'100%'} h={'100%'} top="0" aria-label={accessibilityValue}>
        <Box
          as="circle"
          stroke="currentColor"
          opacity="0.1"
          strokeWidth="2"
          fill="transparent"
          r={radius}
          cx={cx}
          cy={cy}
        />
        <Box
          as="circle"
          stroke="currentColor"
          strokeWidth="2"
          fill="transparent"
          r={radius}
          cx={cx}
          cy={cy}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={progressToStrokeOffset(percentage, circumference)}
          transform="rotate(-90deg)"
          transformOrigin="50% 50%"
          transition="stroke-dashoffset 0.35s"
        />
      </Box>
    </Box>
  )
}

ProgressCircle.propTypes = {
  percentage: PropTypes.number,
  size: PropTypes.number,
  accessibilityValue: PropTypes.string
}

export default ProgressCircle
