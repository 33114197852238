import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import CustomButton from '../../../RadioUI/CustomButton'
import { useUrlTimestamp } from './UrlTimestampContext'
import {
  useEpisodeId,
  useEpisodePercentage,
  useEpisodeProgressAccessibilityValue,
  useEpisodeStartPoint
} from '../hooks/episodeHooks'
import Icon from '../../../RadioUI/Icon'
import ProgressCircle from '../../MyContent/Progresses/ProgressCircle'
import Text from '../../../RadioUI/Text'
import { useContentType } from '../hooks/seriesHooks'
import { useParams } from 'react-router-dom'
import { MEDIA_TYPES } from '../../../components/StickyPlayer/helpers/mediaTypes'
import { useAudioOrVideoPlayerElement } from '../../../components/StickyPlayer/hooks/useAudioOrVideoPlayerElement'
import { useSetUpNextContentContext, useSetUpNextFavoriteLevel } from '../../../components/Queues/UpNext/UpNextContext'

const StartFromButtons = ({ contentContext, favouriteLevel }) => {
  const episodeId = useEpisodeId()
  const { seriesId, seasonId } = useParams()
  const contentType = useContentType()
  const { startPointFromUrl } = useUrlTimestamp()
  const episodePercentage = useEpisodePercentage()
  const episodeProgressAccessibilityValue = useEpisodeProgressAccessibilityValue()
  const episodeStartPoint = useEpisodeStartPoint()
  const { loadAndStartOrPlayFrom } = useAudioOrVideoPlayerElement()
  const setUpNextContentContext = useSetUpNextContentContext()
  const setUpNextFavoriteLevel = useSetUpNextFavoriteLevel()

  const handleFromProgressClick = useCallback(
    event => {
      setUpNextContentContext(contentContext)
      setUpNextFavoriteLevel(favouriteLevel)
      return loadAndStartOrPlayFrom({
        episodeId,
        seriesId,
        seasonId,
        mediaType: contentType === 'podcast' ? MEDIA_TYPES.PODCAST : MEDIA_TYPES.PROGRAM,
        seekTo: episodeStartPoint
      })
    },
    [
      contentType,
      episodeId,
      episodeStartPoint,
      loadAndStartOrPlayFrom,
      seasonId,
      seriesId,
      setUpNextContentContext,
      contentContext,
      setUpNextFavoriteLevel,
      favouriteLevel
    ]
  )

  const handleFromStartClick = useCallback(
    event => {
      return loadAndStartOrPlayFrom({
        episodeId,
        seasonId,
        seriesId,
        mediaType: contentType === 'podcast' ? MEDIA_TYPES.PODCAST : MEDIA_TYPES.PROGRAM,
        seekTo: 0
      })
    },
    [contentType, episodeId, loadAndStartOrPlayFrom, seasonId, seriesId]
  )

  if (!startPointFromUrl) return null

  if (episodePercentage > 0 && episodePercentage !== 100) {
    return (
      <CustomButton onClick={handleFromProgressClick} display="flex" alignItems="center">
        <ProgressCircle
          size={22}
          percentage={episodePercentage}
          accessibilityValue={episodeProgressAccessibilityValue}
          mr="3"
        />
        <Text>Fortsett der du slapp</Text>
      </CustomButton>
    )
  } else {
    return (
      <CustomButton onClick={handleFromStartClick} display="flex" alignItems="center">
        <Icon iconId="nrk-reload" mr="3" />
        Hør fra start
      </CustomButton>
    )
  }
}

StartFromButtons.propTypes = {
  contentContext: PropTypes.string,
  favouriteLevel: PropTypes.string
}

export default StartFromButtons
